import boldBiConfig from "./config";
import axios from "axios";
import VueCookies from "vue-cookies";
import store from "@/store";
import {APP_CONFIG} from "@/utils/constants";

const bBHelper = {
    httpRequest: (url, params, method, cb, authToken, isJSON, isInternal) => {
        const headers = {};

        if (isJSON) {
            headers["Content-Type"] = "application/json";
        }
        if (authToken) {
            headers["Authorization"] = `Bearer ${authToken}`;
        }
        if (isInternal) {
            let token = VueCookies.get("token");
            headers["Authorization"] = `Bearer ${token}`;
            headers["X-Source"] = `(saas-platform) ${APP_CONFIG.name}/${APP_CONFIG.version}`;
            headers["X-User-ID"] = store.getters.getUserAuthDetails.userId;
            headers["X-CASE-ID"] = store.getters.getCaseId == null ? "" : store.getters.getCaseId;
        }

        const httpConfig = {
            method: method,
            url: url,
            headers: headers,
        };

        if (method === "get") {
            httpConfig["params"] = params;
        } else {
            if (Object.keys(params).length > 0) {
                httpConfig["data"] = JSON.stringify(params);
            }
        }
        axios(httpConfig, params)
            .then(function(response) {
                // On Success
                if (response.status >= 200 && response.status < 400) {
                    cb(false, response?.data, authToken);
                }
            })
            .catch(function(error) {
                // On Failure
                console.log("error 123", error);

                cb(error?.response?.data);
            });
    },

    generateEmbedUrl: () => {
        return process.env.VUE_APP_BLACK_BOX_URL + `/api/v1` + boldBiConfig.EMBED_ENDPOINT;
    },
    getDashboardList: () => {
        return new Promise((resolve, reject) => {
            let url = process.env.VUE_APP_BLACK_BOX_URL + `/api/v1/dashboard/dashboards`;
            bBHelper.httpRequest(
                url,
                {},
                "get",
                (err, data) => {
                    if (err) {
                        console.log("dashboard err", err);
                        reject(err);
                    }
                    return resolve(data);
                },
                false,
                true,
                true
            );
        });
    },
};

export default bBHelper;
