<template>
    <div id="editPage">
        <div v-if="toggleViews" style="z-index:10003;" class="tw-fixed tw-shadow-md tw-top-0 tw-right-0 tw-bg-gray-50 tw-h-full tw-w-1/4">
            <div>
                <div class="tw-flex tw-justify-between tw-p-2">
                    <span>Views</span>
                    <span class="tw-cursor-pointer" @click="toggleViews = !toggleViews">close</span>
                </div>

                <div class="tw-grid tw-p-2 tw-gap-2">
                    <div class="tw-flex tw-justify-between hover:tw-bg-gray-200" v-for="view in savedViews" :key="view.id">
                        <span class="tw-cursor-pointer" @click="loadDashboardView(view)">{{ view.name }}</span>
                        <div class="tw-flex tw-gap-2 tw-mr-6">
                            <img @click="handleEmailFrequency(view)" class="tw-cursor-pointer" src="@/assets/icons/types/ico-email.svg" />
                            <img @click="deleteView(view)" class="tw-cursor-pointer" src="@/assets/icons/delete-icon.svg" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal name="file_upload" height="25%" width="30%">
            <div class="tw-flex tw-flex-col tw-max-h-full tw-h-1/4">
                <header class="tw-flex tw-bg-gray-50 tw-border-b tw-border-0 tw-flex-shrink-0 tw-border-solid tw-border-gray-200 tw-items-center tw-p-3 tw-justify-between">
                    <div>Delete view</div>

                    <div @click="hideModal" class="close tw-cursor-pointer tw-text-gray-600 hover:tw-text-gray-800">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" />
                            <path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" />
                        </svg>
                    </div>
                </header>
                <span class="tw-p-3">Are you sure you want to delete this view?</span>

                <footer class="tw-bg-gray-50 tw-flex-shrink-0 tw-flex tw-border-t tw-border-0 tw-border-solid tw-border-gray-200 tw-justify-end">
                    <button label="Cancel" class="tw-w-40 tw-rounded-lg tw-h-10 tw-text-gray-800 tw-bg-gray-200 tw-items-center tw-cursor-pointer tw-mr-2 tw-border-0 tw-text-center">
                        Cancel
                    </button>
                    <button label="Submit" class="tw-w-40 tw-rounded-lg tw-h-10 tw-text-white tw-bg-brand tw-items-center tw-cursor-pointer tw-border-0 tw-text-center">
                        Submit
                    </button>
                </footer>
            </div>
        </modal>
        <modal name="email_frequency" height="auto" width="40%">
            <div class="tw-content-center" v-if="loadingSchedule">
                <neo-loader :loading="true" height="50px" width="50px" />
            </div>
            <div v-show="!loadingSchedule" class="tw-flex tw-flex-col">
                <header class="tw-flex tw-bg-gray-50 tw-border-b tw-border-0 tw-flex-shrink-0 tw-border-solid tw-border-gray-200 tw-items-center tw-p-3 tw-justify-between">
                    <div>Email Frequency</div>

                    <div @click="hideEmailFreqModal" class="close tw-cursor-pointer tw-text-gray-600 hover:tw-text-gray-800">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" />
                            <path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" />
                        </svg>
                    </div>
                </header>
                <div class="tw-w-full tw-p-2">
                    <vue-multiselect v-model="emailFreqEmails" class="input" tag-placeholder="" placeholder="Add emails" label="name" track-by="name" :options="emailOptions" :multiple="true" :checkboxes="true" :preserve-search="false" :taggable="true" :close-on-select="false" :clear-on-select="false" group-values="categories" group-label="group" :show-labels="true" :searchable="true" @tag="addTag">
                        <template slot="tag" slot-scope="props">
                            <span class="tw-rounded-full tw-inline-block tw-mr-2 tw-text-xs tw-p-1 tw-mt-1 tw-mb-2 tw-bg-gray-100">
                                {{ props.option.name }}
                                <button @click="deleteSearch(props.option.name)" class="tw-p-0.5 tw-cursor-pointer" style="all: unset">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 -5 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
                                        <line x1="18" y1="6" x2="6" y2="18" />
                                        <line x1="6" y1="6" x2="18" y2="18" />
                                    </svg>
                                </button>
                            </span>
                        </template>
                        <!--  -->
                    </vue-multiselect>
                </div>

                <div slot="body" class="tw-grid tw-gap-5 tw-h-80 tw-p-5">
                    <div class="tw-flex tw-justify-between tw-items-center tw-w-3/5 tw-pr-6">
                        <label class="tw-text-base tw-justify-center">Type</label>
                        <select @change="handleTest" v-model="selectedRecurrence" id="countries" class="tw-bg-gray-50 tw-border tw-border-gray-300 tw-text-gray-900 tw-text-sm tw-rounded-md focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-block tw-w-1/4 dark:tw-bg-gray-700 dark:tw-border-gray-600 dark:tw-placeholder-gray-400 dark:tw-text-white dark:focus:tw-ring-blue-500 dark:focus:tw-border-blue-500">
                            <option selected>Hourly</option>
                            <option value="Daily">Daily</option>
                            <option value="Weekly">Weekly</option>
                            <option value="Monthly">Monthly</option>
                        </select>
                    </div>

                    <div class="tw-flex tw-justify-between tw-items-center">
                        <label class="tw-text-base tw-justify-center tw-w-24">Recurs</label>
                        <div v-if="selectedRecurrence == 'Hourly'" class="tw-w-3/5 tw-pl-4">
                            <span class="tw-mr-6">Every</span>
                            <div class="timepicker timepicker1" dir="ltr">
                                <input type="number" v-model="recurrenceFields[selectedRecurrence].freqHour" class="hh N" min="0" max="23" placeholder="hh" maxlength="2" @input="validateHours" />:
                                <input type="number" v-model="recurrenceFields[selectedRecurrence].freqMinute" class="mm N" min="0" max="59" placeholder="mm" maxlength="2" />
                            </div>
                            HH:MM
                        </div>
                        <div v-if="selectedRecurrence == 'Weekly'" class="tw-w-3/5 tw-pl-4">
                            <div class="tw-flex tw-gap-2">
                                <label>Every</label>
                                <span class="input-wrapper">
                                    <button id="decrement" :disabled="recurrenceFields[selectedRecurrence].WeeklySchedule.RecurrenceWeeks <= 0" @click="recurrenceFields[selectedRecurrence].WeeklySchedule.RecurrenceWeeks = recurrenceFields[selectedRecurrence].WeeklySchedule.RecurrenceWeeks - 1">-</button>
                                    <input type="number" v-model="recurrenceFields[selectedRecurrence].WeeklySchedule.RecurrenceWeeks" id="quantity" />
                                    <button id="increment" @click="recurrenceFields[selectedRecurrence].WeeklySchedule.RecurrenceWeeks = recurrenceFields[selectedRecurrence].WeeklySchedule.RecurrenceWeeks + 1">+</button>
                                </span>
                                <label>week(s)</label>
                            </div>
                            <div class="tw-flex tw-gap-2 tw-mt-2 tw-flex-wrap">
                                <div v-for="day in dailyScheduleData" :key="day" class="tw-flex tw-gap-2">
                                    <input type="checkbox" @input="handledaysofweek(day)" :id="day.id" :checked="day.checked" />
                                    <label>{{ day.dayName }}</label>
                                </div>
                            </div>
                        </div>
                        <div v-if="selectedRecurrence == 'Daily'" class="tw-flex tw-w-3/5 tw-pl-4">
                            <div class="tw-flex tw-gap-2">
                                <label>Every</label>
                                <span class="input-wrapper">
                                    <button id="decrement" :disabled="recurrenceFields[selectedRecurrence].DailySchedule.EveryNdays <= 0 && recurrenceFields[selectedRecurrence].DailySchedule.EveryWeekday" @click="recurrenceFields[selectedRecurrence].DailySchedule.EveryNdays = recurrenceFields[selectedRecurrence].DailySchedule.EveryNdays - 1">-</button>
                                    <input type="number" :disabled="recurrenceFields[selectedRecurrence].DailySchedule.EveryWeekday" v-model="recurrenceFields[selectedRecurrence].DailySchedule.EveryNdays" value="1" id="quantity" />
                                    <button id="increment" :disabled="recurrenceFields[selectedRecurrence].DailySchedule.EveryWeekday" @click="recurrenceFields[selectedRecurrence].DailySchedule.EveryNdays = recurrenceFields[selectedRecurrence].DailySchedule.EveryNdays + 1">+</button>
                                </span>
                                <label>day(s)</label>
                            </div>

                            <div class="tw-flex tw-gap-2 tw-ml-6">
                                <input type="radio" @click="handleCheckbox('everyweekday')" :checked="recurrenceFields[selectedRecurrence].DailySchedule.EveryWeekday" id="everyweekday" :disabled="recurrenceFields[selectedRecurrence].DailySchedule.EveryNdays > 0" />
                                <label>Every weekday</label>
                            </div>
                        </div>
                        <div v-if="selectedRecurrence == 'Monthly'" class="tw-flex tw-w-3/5 tw-p-4">
                            <div class="tw-flex tw-gap-2">
                                <label>Day</label>
                                <span class="input-wrapper">
                                    <button id="decrement" :disabled="recurrenceFields[selectedRecurrence].MonthlySchedule.DayRecurrence.DayInterval <= 0" @click="recurrenceFields[selectedRecurrence].MonthlySchedule.DayRecurrence.DayInterval = recurrenceFields[selectedRecurrence].MonthlySchedule.DayRecurrence.DayInterval - 1">-</button>
                                    <input type="number" v-model="recurrenceFields[selectedRecurrence].MonthlySchedule.DayRecurrence.DayInterval" value="1" id="quantity" />
                                    <button id="increment" @click="recurrenceFields[selectedRecurrence].MonthlySchedule.DayRecurrence.DayInterval = recurrenceFields[selectedRecurrence].MonthlySchedule.DayRecurrence.DayInterval + 1">+</button>
                                </span>
                                <label>of every</label>
                                <span class="input-wrapper">
                                    <button id="decrement" :disabled="recurrenceFields[selectedRecurrence].MonthlySchedule.DayRecurrence.MonthInterval <= 0" @click="recurrenceFields[selectedRecurrence].MonthlySchedule.DayRecurrence.MonthInterval = recurrenceFields[selectedRecurrence].MonthlySchedule.DayRecurrence.MonthInterval - 1">-</button>
                                    <input type="number" v-model="recurrenceFields[selectedRecurrence].MonthlySchedule.DayRecurrence.MonthInterval" value="1" id="quantity" />
                                    <button id="increment" @click="recurrenceFields[selectedRecurrence].MonthlySchedule.DayRecurrence.MonthInterval = recurrenceFields[selectedRecurrence].MonthlySchedule.DayRecurrence.MonthInterval + 1">+</button>
                                </span>
                                <label>months(s)</label>
                            </div>
                        </div>
                    </div>
                    <div class="tw-flex tw-justify-between tw-items-center">
                        <label>Starts on</label>
                        <div style="z-index:10009;" class="tw-w-3/5 tw-pl-4">
                            <neo-datepicker class="tw-h-10" style="width: 60% !important" placeholder="Select Start Date" v-model="freqStartDate" type="date" value-type="format"></neo-datepicker>
                        </div>
                    </div>
                    <div class="tw-flex tw-justify-between tw-items-center">
                        <label>Ends</label>

                        <div class="tw-flex tw-gap-3 tw-p-3 tw-w-3/5 tw-pr-4">
                            <input type="radio" id="never_radio" :checked="frequencyEnds.never" @click="handleFreqEndType" />
                            <label>Never</label>

                            <input id="after_radio" type="radio" :checked="frequencyEnds.after" @click="handleFreqEndType" />
                            <label>After</label>
                            <span class="input-wrapper">
                                <button id="decrement" @click="handleEndOccurence">-</button>
                                <input type="number" :min="1" v-model="frequencyEnds.occurence" id="quantity" />
                                <button id="increment" @click="frequencyEnds.occurence = frequencyEnds.occurence + 1">+</button>
                            </span>
                            <label>Occurencies</label>
                        </div>
                    </div>
                </div>
                <div></div>
            </div>
            <footer v-if="!loadingSchedule" class="tw-bg-gray-50 tw-flex-shrink-0 tw-flex tw-border-t tw-border-0 tw-border-solid tw-border-gray-200 tw-p-3 tw-justify-end tw-mb-2 tw-h-full tw-mt-8 tw-relative">
                <button @click="setEmailScheduler" label="Submit" class="tw-w-40 tw-rounded-lg tw-h-10 tw-text-white tw-bg-brand tw-items-center tw-cursor-pointer tw-border-0 tw-text-center">
                    Submit
                </button>
            </footer>
        </modal>
        <!-- <button class="tw-my-3 tw-px-5 tw-py-2 tw-text-sm tw-text-white tw-border-0 tw-outline-none tw-ml-2 tw-bg-blue-600 hover:tw-bg-brand-hover tw-font-medium tw-rounded-lg tw-cursor-pointer  tw-drop-shadow-sm" @click="routeBack()">Back</button> -->
        <div id="dashboard_container"></div>
    </div>
</template>

<script>
import {BoldBI} from "@boldbi/boldbi-embedded-sdk";
import BoldBiConfig from "@/boldbi/config";
import biHelper from "@/boldbi";
import store from "@/store";
import {EventBus} from "@/main.js";
import VueMultiselect from "@/components/vue-multiselect";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import dashboardService from "./services.js";
import Loader from "@/components/loader";
import {mapGetters} from "vuex";

export default {
    name: "Bi-Dashboard-View",
    components: {
        "vue-multiselect": VueMultiselect,
        "neo-datepicker": DatePicker,
        "neo-loader": Loader,
    },
    props: {
        backButton: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            frequencyEnds: {
                never: true,
                after: false,
                occurence: 1,
            },
            recurrenceFields: {
                Hourly: {
                    freqMinute: 15,
                    freqHour: 0,
                    HourlySchedule: {
                        ScheduleInterval: null,
                    },
                },
                Daily: {
                    DailySchedule: {
                        RecurrenceType: null,
                        EveryNdays: 0,
                        EveryWeekday: false,
                    },
                },
                Weekly: {
                    WeeklySchedule: {
                        RecurrenceWeeks: 0,
                        RecurrenceDays: [],
                    },
                },
                Monthly: {
                    MonthlySchedule: {
                        RecurrenceType: null,
                        DayRecurrence: {
                            DayInterval: 0,
                            MonthInterval: 0,
                        },
                    },
                },
            },
            selectedView: null,
            selectedRecurrence: "Hourly",
            customBreadCrumbs: [],
            boldBiInstance: null,
            toggleViews: false,
            dashboardInstance: null,
            ans: false,
            savedViews: [],
            emailFreqEmails: [],
            savedEmails: [],
            emailOptions: [{group: "emails", categories: []}],
            freqStartDate: new Date().toISOString().slice(0, -5) + "Z",
            dailyScheduleData: [
                {id: 1, dayName: "Monday", checked: false},
                {id: 2, dayName: "Tuesday", checked: false},
                {id: 3, dayName: "Wednesday", checked: false},
                {id: 4, dayName: "Thursday", checked: false},
                {id: 5, dayName: "Friday", checked: false},
                {id: 6, dayName: "Saturday", checked: false},
                {id: 7, dayName: "Sunday", checked: false},
            ],
            loadingSchedule: true,
        };
    },

    computed: {
        ...mapGetters(["getIsUserAdmin", "getUserAuthDetails"]),
        dashboardId() {
            return this.$route.params.id;
        },
        viewId() {
            if (this?.$route?.query?.view_id) {
                return this.$route.query.view_id;
            } else {
                return null;
            }
        },
    },

    async created() {
        EventBus.$on("showEmailFreq", async (data) => {
            await this.handleEmailFrequency(data);
        });
    },

    async mounted() {
        this.loadDashboardBi(this.dashboardId);
    },

    methods: {
        loadDashboardBi(dashboardId) {
            let boldBiHeader = {
                company_id: store.getters.getAccountId,
            };
            if (this.getIsUserAdmin) {
                boldBiHeader.is_admin = true;
            }
            let boldbiData = {
                serverUrl: BoldBiConfig.BASE_URL,
                dashboardId: dashboardId,
                embedContainerId: "dashboard_container", // This should be the container id where you want to embed the dashboard
                embedType: BoldBI.EmbedType.Component,
                environment: BoldBI.Environment.Enterprise,
                mode: BoldBI.Mode.View,
                dashboardSettings: {
                    beforeSaveViewDialogOpen: function(args) {
                        args.cancel = true;
                        const payload = {
                            filters: args.data.encryptedData,
                            dashboard_id: this.dashboardId,
                            name: "",
                        };
                        EventBus.$emit("saveDashboardView", payload);
                    },
                    filterOverviewSettings: {
                        showSaveIcon: true,
                    },
                },
                height: "100%",
                width: "100%",
                authorizationServer: {
                    url: biHelper.generateEmbedUrl() + `/${this.getUserAuthDetails.userId}` + `/${this.$route.params.id}`,
                    headers: boldBiHeader,
                },
                expirationTime: "100000",
            };
            if (this.viewId) {
                boldbiData.dashboardSettings.filterOverviewSettings = {
                    viewId: this.$route.query.view_id,
                    viewName: this.$route.query.view_name,
                    showSaveIcon: true,
                };
                boldbiData.filterParameters = this.$route.query.filters;
            }
            var boldbiEmbedInstance = BoldBI.create(boldbiData);
            boldbiEmbedInstance.loadDashboard();
        },
        handleFreqEndType() {
            this.frequencyEnds.never = !this.frequencyEnds.never;
            this.frequencyEnds.after = !this.frequencyEnds.after;
        },
        handleEndOccurence() {
            if (this.frequencyEnds.occurence <= 1) {
                this.frequencyEnds.occurence = 1;
            } else {
                this.frequencyEnds.occurence = this.frequencyEnds.occurence - 1;
            }
        },
        handledaysofweek(checkedDay) {
            // this.weekDaysSelected = false;
            this.dailyScheduleData.find((el) => {
                if (el.id === checkedDay.id) {
                    let check = !this.dailyScheduleData.includes(el.label);
                    if (check) {
                        el.checked = true;
                        this.recurrenceFields.Weekly.WeeklySchedule.RecurrenceDays.push(el.dayName);
                    } else {
                        const index = this.recurrenceFields.Weekly.WeeklySchedule.RecurrenceDays.indexOf(el.label);
                        if (index > -1) {
                            this.recurrenceFields.Weekly.WeeklySchedule.RecurrenceDays.splice(index, 1);
                            this.dailyScheduleData.find((el) => {
                                if (el.id === checkedDay.id) {
                                    el.checked = false;
                                }
                            });
                        }
                    }
                }
            });
        },
        handleCheckbox(id) {
            const day = document.getElementById(id);
            if (id === "everyweekday" && day.checked) {
                if (this.recurrenceFields.Daily.DailySchedule.EveryWeekday) this.recurrenceFields.Daily.DailySchedule.EveryWeekday = false;
                else this.recurrenceFields.Daily.DailySchedule.EveryWeekday = true;
            }
        },
        handleFreqEnd() {},
        handleTest(data) {},
        validateScheduler() {
            let message = "";
            if (this.emailFreqEmails.length == 0) {
                message = "Please enter email.";
            }
            if (this.recurrenceFields.Hourly.freqMinute <= 0 || this.recurrenceFields.Hourly.freqHour < 0 || this.recurrenceFields.Hourly.freqHour > 23 || this.recurrenceFields.Hourly.freqMinute > 59) {
                message = "Please enter minimum 15 minutes.";
            }
        },
        async setEmailScheduler() {
            if (parseInt(this.recurrenceFields.Hourly.freqMinute) < 15) {
                this.$toast.error("Minimum time interval is 15 mins.");
                return;
            }
            if (this.emailFreqEmails.length != 0) {
                try {
                    let payload = {
                        name: this.selectedView.name,
                        item_id: this.$route.params.id,
                        start_time: new Date(this.freqStartDate).toISOString().slice(0, -5) + "Z",
                        never_end: this.frequencyEnds.never,
                        end_occurence: !this.frequencyEnds.never ? this.frequencyEnds.occurence : 0,
                        email_list: this.emailFreqEmails.map((res) => res.name),
                        schedule_type: this.selectedRecurrence,
                        email_body: `/bi-dashboard-view/${this.$route.params.id}?view_id=${this.selectedView.viewId}&view_name=${this.selectedView.name}&filters=${this.selectedView.filters}`,
                    };
                    if (this.selectedRecurrence === "Hourly") {
                        this.recurrenceFields.Hourly.HourlySchedule.ScheduleInterval = this.recurrenceFields.Hourly.freqHour + ":" + this.recurrenceFields.Hourly.freqMinute;
                        payload.schedule_data = {HourlySchedule: this.recurrenceFields.Hourly.HourlySchedule};
                    }
                    if (this.selectedRecurrence === "Daily") {
                        this.recurrenceFields.Daily.DailySchedule.RecurrenceType = this.recurrenceFields.Daily.DailySchedule.EveryWeekday ? "EveryWeekday" : "EveryNdays";
                        if (this.recurrenceFields.Daily.DailySchedule.RecurrenceType === "EveryNdays") {
                            this.recurrenceFields.Daily.DailySchedule.EveryWeekday = "";
                        } else {
                            this.recurrenceFields.Daily.DailySchedule.EveryNdays = 0;
                        }
                        payload.schedule_data = this.recurrenceFields.Daily;
                    }
                    if (this.selectedRecurrence === "Weekly") {
                        payload.schedule_data = this.recurrenceFields.Weekly;
                    }
                    if (this.selectedRecurrence === "Monthly") {
                        this.recurrenceFields.Monthly.MonthlySchedule.RecurrenceType = "DayRecurrence";
                        payload.schedule_data = this.recurrenceFields.Monthly;
                    }
                    payload.view_id = this.selectedView.viewId;
                    if (this.selectedView.scheduleId) {
                        payload.schedule_id = this.selectedView.scheduleId;
                        let removeEmailList = [];
                        this.savedEmails.forEach((result) => {
                            if (!this.emailFreqEmails.map((res) => res.name).includes(result)) {
                                removeEmailList.push(result);
                            }
                        });
                        payload.remove_email_list = removeEmailList;
                    }
                    payload = {...payload, ...this.recurrenceFields[this.selectedRecurrence]};
                    dashboardService
                        .addDashboardSchedule(payload)
                        .then((response) => {
                            EventBus.$emit("refreshBookmarks");
                            this.$toast.success("Schedule added successfully.");
                            this.savedEmails = this.emailFreqEmails.map((res) => res.name);
                            this.selectedView.scheduleId = response.data.schedule_id;
                        })
                        .catch((err) => {
                            this.$toast.error(err.response.data.detail.message);
                        });
                } catch (error) {
                    console.log(error);
                    this.$toast.error("Something went wrong.");
                }
            } else {
                this.$toast.error("Please enter email.");
            }
        },
        validateHours() {
            if (this.freqHour > 23) {
                this.freqHour = 23;
            }
            if (this.freqHour < 0) {
                this.freqHour = 0;
            }
            this.recurrenceFields[this.selectedRecurrence].HourlySchedule.ScheduleInterval = this.recurrenceFields[this.selectedRecurrence].freqHour.toString() + ":" + this.recurrenceFields[this.selectedRecurrence].freqMinute.toString();
        },
        deleteSearch(value) {
            let index = this.emailFreqEmails.findIndex((el) => el.name === value);
            if (index > -1) this.emailFreqEmails.splice(index, 1);
        },
        addTag(newTag) {
            let newTags = [];
            newTags = [
                ...newTags,
                ...newTag
                    ?.split(/\t/)[0]
                    ?.split("\n")
                    ?.filter((el) => el && (el !== " " || el !== "")),
            ];

            let newTagsData = newTags.filter(function(item) {
                return item.trim() != "";
            });
            newTagsData.forEach((tg) => {
                if (tg !== "" && tg !== " ") {
                    tg = tg?.trim();
                    const tag = {
                        name: tg,
                        value: tg?.trim(),
                        code: tg.substring(0, 2) + Math.floor(Math.random() * 10000000),
                    };
                    let index = this.emailFreqEmails?.findIndex((item) => item?.value === tg);
                    let secIndex = this.emailOptions[0]?.categories?.findIndex((item) => item?.value === tg);
                    if (index > -1) {
                    } else {
                        this.emailFreqEmails.push(tag);
                    }
                    if (secIndex > -1) {
                    } else {
                        this.emailOptions[0]?.categories.push(tag);
                    }
                }
            });
        },
        async handleEmailFrequency(view) {
            this.loadingSchedule = true;
            this.$modal.show("email_frequency");
            this.selectedView = view;
            const {data} = await dashboardService.getDashboardSchedule(this.selectedView.viewId);
            const scheduleData = data.schedule;
            if (scheduleData != null) {
                this.frequencyEnds = {
                    never: scheduleData.NeverEnd,
                    after: scheduleData.NeverEnd ? false : true,
                    occurence: scheduleData.EndAfterOccurrence == 0 ? 1 : scheduleData.EndAfterOccurrence,
                };
                this.freqStartDate = scheduleData.StartDate;
                this.emailFreqEmails = [];
                scheduleData.ExternalRecipientsList.forEach((res) => {
                    this.emailFreqEmails.push({name: res, value: res});
                    this.savedEmails.push(res);
                });
                this.selectedRecurrence = scheduleData.RecurrenceType;
                this.recurrenceFields[this.selectedRecurrence] = scheduleData[this.selectedRecurrence];
                this.loadingSchedule = false;
            } else {
                this.loadingSchedule = false;
                this.emailFreqEmails = [];
                this.savedEmails = [];
                this.freqStartDate = new Date().toISOString().slice(0, -5) + "Z";
                this.frequencyEnds = {
                    never: true,
                    after: false,
                    occurence: 1,
                };
                this.selectedRecurrence = "Hourly";
            }
        },
        deleteView(view) {
            this.$modal.show("file_upload");
        },
        hideModal() {
            this.$modal.hide("file_upload");
        },
        hideEmailFreqModal() {
            this.$modal.hide("email_frequency");
        },
        loadDashboardView(viewData) {
            this.loadDashboard(this.$route.params.id, true, viewData);
        },
        // Function to adjust the iframe height
        adjustIframeHeight() {
            var iframe = document.getElementById("dashboard-frame");
            if (iframe) {
                iframe.height = Math.max(document.documentElement.scrollHeight) + "px";
            }
        },

        routeBack() {
            this.$router.push({name: "Dashboard List"});
        },
        handleDocClick(event) {
            if (event?.target?.id != "dashboard_container_embeddedbi_otheroption" && !this.checkdropdownClick(event?.target)) {
                let dropdownElement = document.getElementById("dashboard_container_embeddedbi_ConfigMenu");
                if (dropdownElement) {
                    dropdownElement.style.display = "none";
                }
            }
        },
        checkdropdownClick(docId) {
            let dropdownClicked = false;
            if (docId?.id == "dashboard_container_embeddedbi_ConfigMenu" || docId?.parentElement.id == "export") {
                dropdownClicked = true;
            }
            return dropdownClicked;
        },
    },
    beforeDestroy() {
        document.removeEventListener("click", this.handleDocClick);
        EventBus.$off("showEmailFreq");
    },
    watch: {
        "$route.params.id": function(newValue, oldValue) {
            const dashboardId = newValue;
            this.loadDashboardBi(dashboardId);
            EventBus.$emit("refreshBookmarks");
        },
    },
};
</script>
<style scoped lang="scss">
.neo-datepicker {
    display: flex;
    justify-content: space-between;

    ::v-deep {
        select {
            border: 1px solid #ced4da;
            background-color: white;
            // height: 10px;
        }
    }
}

.input {
    position: relative;
    margin-right: 0.25rem;
    user-select: none;
    font-family: var(--brand-font);
    .dropdown {
        width: inherit;
        // height: 2.2rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        box-sizing: border-box;
        transition: var(--transition-mnml);
        border-bottom: 1px solid transparent;
        .display {
            width: 100%;
            .label {
                text-align: left;
                margin: 0;
                color: var(--text-fade-color);
                font-size: 12px;
            }
            span {
                display: inline-flex;
                align-items: center;
                width: 100%;
                img {
                    width: 1.5rem;
                    border-radius: var(--element-border);
                }
                input {
                    outline: none;
                    border: none;
                    width: 95%;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    font-size: 14px;
                    background-color: transparent;
                    &::placeholder {
                        color: var(--text-fade-color);
                    }
                }
            }
        }
    }
    .dropdown-content {
        position: absolute;
        right: 1px;
        margin-top: 3px;
        width: 125px;
        background-color: #ffffff;
        z-index: 25;
        max-height: 256px;
        // overflow: scroll;
        overflow-x: hidden;
        border-radius: 0 0 var(--element-border) var(--element-border);
        &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
        }
        &::-webkit-scrollbar-track {
            background: transparent;
        }
        &::-webkit-scrollbar-thumb {
            background: #a8b5c9;
            border-radius: 20rem;
        }
        &::-webkit-scrollbar-corner {
            background: transparent;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: #9cadc6;
        }
        ul {
            list-style: none;
            width: 100%;
            margin: 0;
            padding: 0;
        }
    }
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.timepicker {
    border: 1px solid rgb(42, 42, 42);
    text-align: center;
    display: inline;
    border-radius: 4px;
    padding: 2px;
}

.timepicker .hh,
.timepicker .mm {
    width: 30px;
    outline: none;
    border: none;
    text-align: center;
}

.timepicker.valid {
    border: solid 1px springgreen;
}

.timepicker.invalid {
    border: solid 1px red;
}
#list {
    padding: 10px;
    border-radius: 8px;
    border: 1px solid rgba(218, 218, 218, 0.87);
    background: #fff;
    margin-bottom: 12px;
}

#editPage {
    width: 100%;
    height: 100%;
}
#dashboard_container {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 0;
}

.input-wrapper {
    width: 100px;
    height: 20px;
    display: flex;
    border-radius: 50%;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
    text-align: center;
}

.input-wrapper * {
    border: none;
    width: 20px;
    flex: 1;
}

.input-wrapper button {
    cursor: pointer;
}

.input-wrapper button:first-child {
    color: red;
}

.input-wrapper button:last-child {
    color: green;
}
</style>
