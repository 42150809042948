const boldBiConfig = {
    BASE_URL: process.env.VUE_APP_BOLDBI_API_BASE_URL,
    EMBED_ENDPOINT: "/dashboard/embed/auth",
    DASHBOARD_LIST_ENDPOINT: "/v4.0/dashboards",
    DASHBOARD_DATA_ENDPOINT: "/v4.0/dashboards/",
    DASHBOARD_DELETE_ENDPOINT: "/v4.0/dashboards/",
    DASHBOARD_UPDATE_FAVORITE_ENDPOINT: "/v4.0/dashboards/favorite",
    CREATE_DRAFT_DASHBOARD: "/v4.0/dashboards/drafts",
};
export default boldBiConfig;
